export type Trinary = 'YES' | 'NO' | null;

export interface AgreementSet {
  id: string;
  acknowledgedUnitAccountDisclosures?: boolean;
  authorizedAboundEftpsEnrollment?: boolean;
  isPrivacyDataUseAgree?: boolean;
  isPrivacyTruthfulAgree?: boolean;
  isPrivacyAuthorizeAgree?: boolean;
}

export enum Situation {
  /** META */
  MANUAL_ONLY = 'MANUAL_ONLY',

  /** HEALTH */
  HEALTH = 'HEALTH',
  NO_HEALTH = 'NO_HEALTH',
  FFM_STATE = 'FFM_STATE',
  KNOWN_NON_FFM = 'KNOWN_NON_FFM',
  ASSUMED_FFM = 'ASSUMED_FFM',
  NEED_HEALTH_DOCS = 'NEED_HEALTH_DOCS',
  HEALTH_IN_PROGRESS = 'HEALTH_IN_PROGRESS',
  MANUAL_ID_REVIEW = 'MANUAL_ID_REVIEW',

  /** OPEN ENROLLMENT */
  OPEN_ENROLLMENT = 'OPEN_ENROLLMENT',
  WINDOW_SHOPPING = 'WINDOW_SHOPPING',
  NOT_OPEN_ENROLLMENT = 'NOT_OPEN_ENROLLMENT',
  NOT_WINDOW_SHOPPING = 'NOT_WINDOW_SHOPPING',
  NO_CURRENT_APPS = 'NO_CURRENT_APPS',
  NO_CURRENT_POLICIES = 'NO_CURRENT_POLICIES',
  HAS_ENROLLED = 'HAS_ENROLLED',
  HAS_NOT_ENROLLED = 'HAS_NOT_ENROLLED',

  /** RANDOM */
  DOCS_AVAILABLE = 'DOCS_AVAILABLE',
}

export interface BaseArgs {
  loading?: boolean;
  testID?: string;
  style: any;
}

// display components are react components with an added function for should display
export type DisplayComponent<Props extends {}> = React.FC<Props> & {
  shouldDisplay: (props: Props) => Boolean;
};

export type OnboardingBenefit =
  | 'HEALTH'
  | 'HEALTH_EXPRESS'
  | 'HEALTH_RENEW'
  | 'HEALTH_LINK'
  | 'HEALTH_SLASH'
  | 'TAX'
  | 'RETIREMENT';

export type OnboardingType =
  | 'Full'
  | 'HealthApplication'
  | 'HealthExplorer'
  | 'HealthRenew'
  | 'HealthSBM'
  | 'PremiumSlasher'
  | 'GoalSetup';

export type SignupContext =
  | 'PREMIUM_SLASHER'
  | 'HEALTH_LINK'
  | 'HEALTH_APPLICATION'
  | 'HEALTH_RENEW'
  | 'TAX'
  | 'RETIREMENT'
  | 'SAVINGS';
