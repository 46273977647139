import React, { useMemo } from 'react';
import { useLocale, useCopy } from '@app/utils';
import { Text, Asset } from '@uikit';
import { useAuth } from '@auth';
import { Stack } from '@layouts';
import { useQuery, UserQuery } from '@data';
import { Route } from '@types';
import Menu from './Menu';
import NavLink from './NavLink';
import UserIcon from '@svg/user.svg';

const AvatarMenu = ({ canToggleLanguage }) => {
  const { nextLanguage } = useLocale();
  const { isOnboarded, logout } = useAuth();
  const { c } = useCopy('catch.module.me');
  const { loading, data } = useQuery(UserQuery);

  const { userID, email, avatarImage } = useMemo(() => {
    return {
      userID: data?.viewer?.user?.id,
      email: data?.viewer?.user?.email,
      avatarImage: data?.viewer?.user?.avatarImage,
    };
  }, [data]);

  return (
    <Menu
      iconComponent={
        <Asset
          svg={!avatarImage && UserIcon}
          base64={avatarImage}
          alt="user menu"
          id={userID}
          loading={loading}
          shape="circle"
          size={avatarImage ? 'md' : 'xs'}
          containerSize="md"
          bg="skeleton"
          bgHover="border"
        />
      }
    >
      <Stack spacing="0b">
        <Text size="sm" weight="regular" color="subtle">
          {email}
        </Text>
        <>
          {isOnboarded && (
            <>
              <NavLink action={{ type: 'ROUTE', route: Route.ACCOUNT_INFO }} stacked small>
                {c('MeView.infoLink')}
              </NavLink>
              <NavLink action={{ type: 'ROUTE', route: Route.ACCOUNT_SETTINGS }} stacked small>
                {c('MeView.settingsLink')}
              </NavLink>
              <NavLink action={{ type: 'ROUTE', route: Route.STATEMENTS }} stacked small>
                {c('MeView.documentsLink')}
              </NavLink>
              {canToggleLanguage && (
                <NavLink action={{ type: 'TOGGLE_LANGUAGE' }} stacked small>
                  {nextLanguage()}
                </NavLink>
              )}
            </>
          )}
          <NavLink action={{ type: 'HELP' }} stacked small>
            {c('MeView.helpLink')}
          </NavLink>
          <NavLink
            action={{ type: 'CUSTOM', onAction: logout }}
            stacked
            small
            urgent
            color="credit"
          >
            {c('SignOutButton')}
          </NavLink>
        </>
      </Stack>
    </Menu>
  );
};

export default AvatarMenu;
