const isNodeEnv = (str) => process.env.NODE_ENV === str;
const isCoveredEnv = (str) => process.env.REACT_APP_COVERED_ENV === str;
const isDesktop = (_) => process.env.REACT_APP_PLATFORM === 'desktop';
const isSafari = (_) => /Apple/.test(navigator.vendor);

const environments = {
  // Prod/Stage/Dev are the primary environments we have our applications
  // deployed in.
  isProd: isNodeEnv('production') && isCoveredEnv('prod'),
  isStage: isNodeEnv('production') && isCoveredEnv('stage'),
  isDev: isNodeEnv('production') && isCoveredEnv('dev'),

  // isLocal is used for when the dev server hot reloading is happening
  isLocal: isNodeEnv('development'),

  // isProdLike is used for whenever we're building code in a
  // minified/production like way.
  isProdLike: isNodeEnv('production'),
  // isDevLike is used for whenever we're in an environment used for developers
  // to sandbox/experiment.
  isDevLike: isNodeEnv('development') || isCoveredEnv('dev'),
  isHealth: process.env.REACT_APP_PHASE_TWO === 'ON',
  isGeorgia: process.env.REACT_APP_GEORGIA_ACCESS === 'ON',

  // isTest is used when the test suite is running
  isTest: isNodeEnv('test'),

  // Should be fairly reliable...
  isSafari: isSafari(),

  // ask Andrew
  isDesktop: isDesktop(),
  isInternal: !isCoveredEnv('prod'),
};

const analytics = {
  segmentKey: process.env.REACT_APP_SEGMENT_KEY,
};

const graphql = {
  uri: process.env.REACT_APP_GRAPHQL_URL,
  fintechUri: process.env.REACT_APP_FINTECH_URL,
};

const graphcms = {
  uri: process.env.REACT_APP_GRAPHCMS_URL,
};

const envNames = {
  local: 'Dev',
  dev: 'Dev',
  stage: 'Stage',
};

const plaidEnvs = {
  dev: 'sandbox',
  stage: 'sandbox',
  prod: 'production',
};

const adminUrls = {
  dev: 'https://at.dev.catch.co',
  stage: 'https://at.stage.catch.co',
  prod: 'https://at.catch.co',
};

const logrocket = {
  dev: '6an89b/catch-dev',
  stage: '6an89b/catch-dev',
  prod: '6an89b/catch-production',
};

const datadogrum = {
  dev: { applicationId: 'cf80e8fb-c1d0-4e7a-a309-96252ebdc9f5', env: 'development' },
  stage: { applicationId: 'd60484a5-8e9b-46b6-9c87-ff7201dd0e06', env: 'staging' },
  prod: { applicationId: 'bf01a6ef-8195-4bcc-a73b-c90293a23344', env: 'production' },
};

const webUrls = {
  prod: 'https://app.catch.co',
  stage: 'https://stage.catch.co',
  dev: 'https://dev.catch.co',
  local: 'https://dev.catch.co',
};

const marketplaceKeys = {
  dev: 'd687412e7b53146b2631dc01974ad0a4',
  stage: 'd687412e7b53146b2631dc01974ad0a4',
  prod: 'uplvuvRrQRzAWlleHNXfPcN5qkdnVJBg',
};

const features = {
  healthEDE: true,
};

const key = process.env.REACT_APP_COVERED_ENV || 'dev';

export default {
  ...environments,
  graphql,
  graphcms,
  analytics,
  envName: envNames[key],
  isSentryEnabled: environments.isProd,
  plaidEnv: plaidEnvs[key],
  features,
  adminUrl: adminUrls[key],
  webUrl: webUrls[key],
  marketplaceKey: marketplaceKeys[key],
  logrocket: logrocket[key],
  datadogrum: datadogrum[key],
};
