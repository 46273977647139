import { Color } from '@app/styles';
/**
 * Possible sizes for Assets
 */
export type AssetSize =
  | 'mini'
  | 'glyph'
  | 'xs'
  | 'sm'
  | 'md'
  | 'lg'
  | 'xl'
  | 'xxl'
  | 'xxxl'
  | 'plus'
  | 'jumbo'
  | 'humongous';

export type AssetShape = 'squircle' | 'round' | 'circle';

export enum TextAlign {
  left = 'left',
  right = 'right',
  center = 'center',
}

export enum FontSize {
  h1 = 'h1',
  h2 = 'h2',
  h3 = 'h3',
  h4 = 'h4',
  h5 = 'h5',
  h6 = 'h6',
  btn = 'btn',
  p = 'p',
  fp = 'fp',
  lg = 'lg',
  xl = 'xl',
  sm = 'sm',
  xs = 'xs',
  label = 'label',
}

export enum FontWeight {
  light = 'light',
  regular = 'regular',
  medium = 'medium',
  semibold = 'semibold',
  bold = 'bold',
}

export enum FontFace {
  display = 'InterDisplay',
  body = 'Inter',
  signature = 'Caveat',
}

export interface UniversalTextProps {
  family?: FontFace;
  size?: FontSize;
  weight?: FontWeight;
  align?: TextAlign;
  color?: Color;
}

// Colors
export type TextColor = string;
export type BackgroundColor = string;
export type SkeletonColor = string;
export type BorderColor = string;

export type SemanticColor =
  | 'brand'
  | 'taxes'
  | 'income'
  | 'saved'
  | 'retirement'
  | 'coverage'
  | 'brandLight'
  | 'incomeLight'
  | 'taxesLight'
  | 'retirementLight'
  | 'savedLight'
  | 'coverageLight'
  | 'positive'
  | 'positiveLight'
  | 'medium'
  | 'mediumLight'
  | 'negative'
  | 'negativeLight';

export type RenderedIcon =
  | 'brand'
  | 'taxes'
  | 'income'
  | 'saved'
  | 'retirement'
  | 'coverage'
  | 'taxesAlt'
  | 'savedAlt'
  | 'retirementAlt'
  | 'coverageAlt'
  | 'link'
  | 'cog'
  | 'faceid'
  | 'dental'
  | 'vision'
  | 'life'
  | 'timeOff'
  | 'emergencySavings'
  | 'familyLeave'
  | 'healthExpenses'
  | 'cake'
  | 'car'
  | 'gift'
  | 'house'
  | 'laptop'
  | 'luggage'
  | 'target'
  | 'tools'
  | 'bell'
  | 'pay'
  | 'search'
  | 'priceTag'
  | 'bag'
  | 'person'
  | 'pet'
  | 'safe'
  | 'CUSTOM'
  | 'FAMILY_LEAVE'
  | 'HEALTH_EXPENSES'
  | 'PAID_TIME_OFF'
  | 'RETIREMENT_SAVINGS'
  | 'TAX_SAVINGS'
  | 'EMERGENCY_SAVINGS';

/**
 * Colors that can be used as base colors
 * - That means layouts, modals, sheets, navs, gradients
 * should only be using the options for base colors
 */
export type BaseColor =
  | 'transparent'
  | 'page'
  | 'sheet'
  | 'brandLight'
  | 'incomeLight'
  | 'taxesLight'
  | 'retirementLight'
  | 'savedLight'
  | 'coverageLight';

/**
 * The possible corners that can be applied
 * Note that there is a range of sizes from none to round
 * We can either apply the size to all corners
 * or use a rect to only apply it to individual corners
 */
export type CornerSize = 'none' | 'micro' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'round';

// all of these are optional; if any individual corner is not defined,
// we're assuming its a 'none' corner
export type CornerRect = {
  topLeft?: CornerSize;
  topRight?: CornerSize;
  bottomLeft?: CornerSize;
  bottomRight?: CornerSize;
};

export type Corners = CornerSize | CornerRect;
// These options are used to style an entire component like  Card, etc.
// While the options may be similar to other color options, this type
// should not be used to style an atomic component like Text.
export type PaletteColor =
  | 'confirm'
  | 'delete'
  | 'brand'
  | 'recommendation'
  | 'auto'
  | 'success'
  | 'error'
  | 'highlight'
  | 'active';

// These options are used to style an entire component like  Card, etc.
// While the options may be similar to other color options, this type
// should not be used to style an atomic component like Text.
export type ImportanceLevels = 'vital' | 'important' | 'consider' | 'draft';

export type AssetProps = {
  testID?: string;
  loading?: boolean;
  onPress?: any;
  disabled?: boolean;
  base64?: string;
  render?: RenderedIcon;
  // @todo create icon ts type
  icon?: any;
  uri?: string;
  img?: any;
  svg?: any;
  text?: string;
  element?: any;
  badge?: string | boolean;
  size?: AssetSize;
  containerSize?: AssetSize;
  textSize?: FontSize;
  align?: TextAlign;
  shape?: AssetShape;
  width?: boolean;
  height?: boolean;
  shadow?: 'major' | 'card';
  border?: boolean;
  bg?: BackgroundColor;
  bgHover?: BackgroundColor;
  bgActive?: BackgroundColor;
  hex?: string;
  color?: TextColor;
  fontWeight?: FontWeight;
  style?: any;
  containerStyle?: any;
  containerInteractionStyle?: any;
  borderColor?: any;
  pressableProps?: any;
  gradient?: string;
  absolute?: boolean;
};

export type InteractionAccessoryType =
  | 'select'
  | 'caret'
  | 'radio'
  | 'navigation'
  | 'switch'
  | 'dismiss'
  | 'loading';

export type AccessoryType =
  | 'loading'
  | 'down'
  | 'up'
  | 'arrow'
  | 'edit'
  | 'check'
  | 'x'
  | 'lock'
  | 'menu'
  | 'warning'
  | 'error'
  | 'selected'
  | 'unselected'
  | 'other'
  | 'add'
  | 'link'
  | 'chevron'
  | 'chevronUp'
  | 'radio'
  | 'circle';

export type LayoutMode = 'page' | 'sheet';

export type NavMode = 'root' | 'flow' | 'split';

export type PresentationMode =
  | 'largeSheet'
  | 'pageSheet'
  | 'formSheet'
  | 'actionSheet'
  | 'basicSheet'; // designed to match the website and BasicLayout
