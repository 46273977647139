import { Button } from '@uikit';
import { ButtonGroup } from '@layouts';
import { useCopy } from '@app/utils';

export interface GoalActionsProps {
  loading?: boolean;
  isLocked?: boolean;
  balance: number;
  percentage: number;
  editPercentage: () => void;
  openTransfer: () => void;
}

/**
 * GoalActions
 *
 */
export const GoalActions = ({
  loading,
  isLocked,
  balance,
  percentage,
  editPercentage,
  openTransfer,
}: GoalActionsProps) => {
  const { c, $ } = useCopy('catch');

  return (
    <ButtonGroup loading={loading} textRight={$(balance)} textLeft={`${percentage}%`}>
      <Button testID="edit-percentage" disabled={isLocked} full onPress={editPercentage} alt>
        {c('basics.edit')}
      </Button>
      <Button testID="start-transfer" disabled={isLocked} full onPress={openTransfer}>
        {c('basics.transfer')}
      </Button>
    </ButtonGroup>
  );
};
