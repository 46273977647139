import React, { useMemo } from 'react';
import {
  GET_BANKING_SETUP_DETAILS,
  UPDATE_BANKING_SETUP_USER,
  UpdateUserInput,
  useMutation,
  useQuery,
} from '@data';
import { BasicFormBlueprint } from '@app/blueprints';
import { fields } from '@app/config';
import { Route, Copy, FieldConfig } from '@types';
import { formatMaskedPayload } from '@app/utils';

type InfoType = 'name' | 'dob' | 'address' | 'ssn' | 'occupation' | 'phone';

const BankingSetupInfo: React.FC<{
  type: InfoType;
  title: string;
  subtitle?: Copy;
  field: FieldConfig;
  handleNext: () => void;
}> = ({ type, title, subtitle, field, handleNext }) => {
  const fields = [field];

  const { loading, data } = useQuery(GET_BANKING_SETUP_DETAILS);
  const [updateUser, { loading: submitting }] = useMutation(UPDATE_BANKING_SETUP_USER);

  const initialValues = useMemo(() => {
    const values: Record<InfoType, object> = {
      name: {
        givenName: data?.me.user.givenName,
        middleName: data?.me.user.middleName,
        familyName: data?.me.user.familyName,
        nameSuffix: data?.me.user.nameSuffix,
      },
      dob: {
        dob: data?.me.user.dob,
      },
      phone: {
        phone: data?.me.user.phoneNumber,
      },
      address: {
        legalAddress: {
          street1: data?.me.user.legalAddress?.street1,
          street2: data?.me.user.legalAddress?.street2,
          city: data?.me.user.legalAddress?.city,
          state: data?.me.user.legalAddress?.state,
          zip: data?.me.user.legalAddress?.zip,
        },
      },
      ssn: {
        ssn: data?.me.user.ssn ? `***-**-${data?.me.user.ssn}` : '',
      },
      occupation: {
        occupation: data?.me.user.occupation,
      },
    };

    return values[type];
  }, [data, type]);

  const formConfig = {
    initialValues,
    fields,
    onSubmit: (values) => {
      const inputs: Record<InfoType, UpdateUserInput> = {
        name: {
          givenName: values.givenName,
          middleName: values.middleName,
          familyName: values.familyName,
          nameSuffix: values.nameSuffix,
        },
        dob: {
          dob: values.dob,
        },
        phone: {
          phoneNumber: values.phoneNumber,
        },
        address: {
          legalAddress: {
            street1: values.legalAddress?.street1,
            street2: values.legalAddress?.street2,
            city: values.legalAddress?.city,
            state: values.legalAddress?.state,
            zip: values.legalAddress?.zip,
          },
        },
        ssn: {
          ssn: formatMaskedPayload(values.ssn),
        },
        occupation: {
          occupation: values.occupation,
        },
      };

      updateUser({
        variables: { input: inputs[type] },
        onCompleted: () => handleNext(),
      });
    },
  };

  return (
    <BasicFormBlueprint
      loading={loading}
      submitting={submitting}
      title={title}
      subtitles={[subtitle]}
      formConfig={formConfig}
    />
  );
};

export const BankingSetupNameView = {
  name: Route.BANKING_SETUP_NAME,
  component: (props) => (
    <BankingSetupInfo {...props} type="name" title="Legal name" field={fields.LEGAL_NAME} />
  ),
  options: {},
};

export const BankingSetupDOBView = {
  name: Route.BANKING_SETUP_DOB,
  component: (props) => (
    <BankingSetupInfo {...props} type="dob" title="Date of birth" field={fields.DOB} />
  ),
  options: {},
};

export const BankingSetupPhoneView = {
  name: Route.BANKING_SETUP_PHONE,
  component: (props) => (
    <BankingSetupInfo {...props} type="phone" title="Phone number" field={fields.PHONE_NUMBER} />
  ),
  options: {},
};

export const BankingSetupAddressView = {
  name: Route.BANKING_SETUP_ADDRESS,
  component: (props) => (
    <BankingSetupInfo
      {...props}
      type="address"
      title="Current home address"
      field={fields.LEGAL_ADDRESS}
    />
  ),
  options: {},
};

export const BankingSetupOccupationView = {
  name: Route.BANKING_SETUP_OCCUPATION,
  // @todo: set field
  component: (props) => (
    <BankingSetupInfo {...props} type="occupation" title="Occupation" field={fields.OCCUPATION} />
  ),
  options: {},
};

export const BankingSetupSSNView = {
  name: Route.BANKING_SETUP_SSN,
  component: (props) => (
    <BankingSetupInfo {...props} type="ssn" title="Social Security Number" field={fields.SSN} />
  ),
  options: {},
};
