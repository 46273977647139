import { gql } from '../generated';

export const HealthExplorerQuery = gql(`
  query HealthExplorer {
    viewer {
      id
      user {
        id
        signupParams
        signupCode
        estimatedW2Income
        estimated1099Income
        spouseIncome
        workType
        filingStatus
        dob
        age @client
        legalAddress {
          zip
        }
      }
    }
    viewerTwo {
      id
      health {
        healthPreference {
          ownerID
          financialPreference
          plannedUsage
          drugsWanted {
            name
            rxcui
          }
          providersWanted {
            name
            npi
          }
        }
      }
      healthExplorerData {
        id
        pathwayType
        pathway
        zipcode
        countyfips
        state
        coverageYear
        income
        possibleCoverageYears
        canCreateEnrollment
        handoffUrl
        providerPlanID
        dependents {
          id
          age
          sex
          relation
          aptcEligible
          hasMec
          isApplying
          isParent
          isPregnant
          isSmoker
        }
        healthPlanDetails {
          id
          name
        }
        healthPlans {
          plans {
            id
          }
        }
      }
    }
    reference {
      health {
        openEnrollmentDates {
          ...OEDates
        }
      }
    }
  }
`);
