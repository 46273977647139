import { useCopy } from '@app/utils';
import { exit } from '@navigate';
import { ActionType, Route } from '@types';
import { usePlaid } from '../hooks';
import { HiccupBlueprint } from '@app/blueprints';

export interface AddBankLinkPageProps {
  handleNext?: () => void;
}

export function AddBankLinkPage({ handleNext }: AddBankLinkPageProps) {
  const { c: basics } = useCopy('catch.basics');

  const { disabled, loading, prefetch, openLink } = usePlaid({
    onSuccess: () => {
      if (handleNext) {
        handleNext();
      } else {
        exit(Route.HOME);
      }
    },
  });

  const actions = [
    {
      testID: 'link-new-bank',
      type: 'CUSTOM' as ActionType,
      color: 'brand',
      onAction: openLink,
      onHover: prefetch,
      loading,
      disabled,
      label: basics('chooseBank'),
    },
  ];

  return (
    <HiccupBlueprint
      loading={false}
      asset={{ render: 'link', gradient: undefined }}
      title={basics('linkYourBank')}
      subtitles={[basics('linkYourBankDescription')]}
      actions={actions}
    />
  );
}
