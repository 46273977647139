import React from 'react';
import { openIntercom, useCopy } from '@app/utils';
import { open, SheetComponentProps } from '@navigate';
import { ComplexRow, Loading, SimpleRow } from '@uikit';
import { SheetBlueprint } from '@blueprints';
import { Stack } from '@layouts';
import Withdraw from '@svg/arrow-up-left.svg';
import Deposit from '@svg/arrow-down-right.svg';
import { BankTransferDirection } from '@app/data';
import { Route } from '@app/types';

const assetProps = {
  shape: 'circle',
  size: 'xs',
  containerSize: 'md',
};

const TransferOptionsSheet: React.FC<SheetComponentProps['TRANSFER_OPTIONS']> = ({ goal }) => {
  const { c } = useCopy('catch');

  const headers = {
    needsLink: {
      title: c('basics.linkYourBank'),
      subtitle: c('basics.linkYourBankDescription'),
    },
    default: {
      title: c('basics.transfer'),
    },
  };

  const disabledDeposits = {};
  const disabledWithdrawals = {};
  const hasAnyBankLink = true;
  const loading = false;

  const openTransfer = (direction: BankTransferDirection) => {
    open(Route.TRANSFER_AMOUNT, { direction, goal });
  };

  const onDisabled = (reason) => {
    switch (reason) {
      case 'BANKLINK_ACTIONABLE':
        // return () => navigate(Route.LINKED_BANKS);
        return;
      case 'BANKLINK_TEMP':
      case 'NO_BALANCE_TO_WITHDRAW':
      case 'ACCOUNT_TIER':
      case 'LOCKED':
        return openIntercom;

      case 'DENIED':
      case 'REVIEW':
      case 'NOT_SETUP':
      default:
        return undefined;
    }
  };

  const options = [
    {
      direction: BankTransferDirection.DepositIntoCatch,
      asset: {
        svg: Deposit,
        color: 'fg',
        bg: 'savedLightColor',
        ...assetProps,
      },
      label: c('basics.depositLong', {
        destination: goal?.name || 'Catch',
      }),
      disabled: disabledDeposits?.disabled,
      reason: c(`transfer.disabled.${disabledDeposits?.reason}`),
      onDisabled: onDisabled(disabledDeposits?.reason),
    },
    {
      direction: BankTransferDirection.WithdrawFromCatch,
      label: c('basics.withdrawLong', {
        source: goal?.name || 'Catch',
      }),
      disabled: disabledWithdrawals?.disabled,
      reason: c(`transfer.disabled.${disabledWithdrawals?.reason}`),
      onDisabled: onDisabled(disabledWithdrawals?.reason),
      asset: {
        svg: Withdraw,
        color: 'fg',
        bg: 'coverageLightColor',
        ...assetProps,
      },
    },
  ];

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <SheetBlueprint
          {...headers[!hasAnyBankLink ? 'needsLink' : 'default']}
          altRows={hasAnyBankLink}
          hasBottomActions // this is required because the rows are acting like bottom actions for spacing purposes
        >
          {hasAnyBankLink && (
            <Stack separatorComponent>
              {options.map((option) => (
                <>
                  <ComplexRow
                    key={option.direction}
                    inset
                    onPress={() => openTransfer(option.direction)}
                    disabled={option.disabled}
                    asset={option.asset}
                    label={option.label}
                  />
                  {option.reason && (
                    <SimpleRow
                      inset
                      label={option.reason}
                      onPress={option.onDisabled}
                      accessory={option.onDisabled ? 'arrow' : undefined}
                    />
                  )}
                </>
              ))}
            </Stack>
          )}
        </SheetBlueprint>
      )}
    </>
  );
};

export default TransferOptionsSheet;
