import { Routes, Route, Outlet, Navigate, useLocation, useSearchParams } from 'react-router-dom';

import { useAuth } from '@auth';
import { Loading } from '@uikit';
import { normalizePath, paths, prefixes, Screen } from '@navigate';
import { guest as GuestStack, onboarding as OnboardingStack, sorted } from './config';
import MainLayout from '@app/web/MainLayout';
import { Welcome } from './onboarding/WelcomeView';
import { useSignupParams } from '@app/auth/useSignupParams';
import { useEffect } from 'react';

export function RequireAuth() {
  const { isAuthenticated, user } = useAuth();

  if (!isAuthenticated) {
    return <Navigate to={paths.LOGIN} />;
  }

  if (isAuthenticated && !user) {
    return <Loading full page />; // @todo - what to show while we are doing user creation?
  }

  return <Outlet />;
}

export function RequireGuest() {
  const { isAuthenticated } = useAuth();

  if (isAuthenticated) {
    return <Navigate to={paths.HOME} />;
  }

  return <Outlet />;
}

export function ReturnTo() {
  const { setParams } = useSignupParams();

  const location = useLocation();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (location.pathname !== '/') {
      const returnTo = `${location.pathname}?${searchParams.toString()}`;
      setParams({ returnTo });
    }
  }, []);

  return <Navigate to={paths.LOGIN} />;
}

const routes = sorted.map((obj) => {
  if (obj.config?.stackName) {
    const Stack = obj;
    return (
      <Route
        key={obj.config.stackName}
        index
        path={normalizePath({ stack: obj.config.stackName })}
        element={<Stack layout="page" />}
      />
    );
  } else {
    return (
      <Route
        key={obj.name}
        path={normalizePath({ route: obj.name })}
        element={<Screen layout="page" name={obj.name} Component={obj.component} />}
      />
    );
  }
});

export function AppRoutes() {
  const { isLoading, isAuthenticated, isOnboarded, shouldHandleRedirect } = useAuth();

  if (isLoading) {
    return <Loading full page />;
  }

  return (
    <Routes>
      {isAuthenticated ? (
        <Route path="*" element={<RequireAuth />}>
          <Route path="welcome" element={<Welcome />} />
          <Route path="*" element={<MainLayout />}>
            {!isOnboarded ? (
              <>
                <Route
                  index
                  path={normalizePath({ stack: OnboardingStack.config.stackName })}
                  element={<OnboardingStack />}
                />
                <Route path="*" element={<Navigate to={prefixes.ONBOARDING_STACK} />} />
              </>
            ) : shouldHandleRedirect ? (
              <>
                <Route path="*" element={<Navigate to={paths.WELCOME} />} />
              </>
            ) : (
              <>
                {routes}
                <Route path="*" element={<Navigate to={paths.HOME} />} />
              </>
            )}
          </Route>
        </Route>
      ) : (
        <Route path="/*" element={<RequireGuest />}>
          <Route
            index
            path={normalizePath({ stack: GuestStack.config.stackName })}
            element={<GuestStack />}
          />
          <Route path="*" element={<ReturnTo />} />
        </Route>
      )}
    </Routes>
  );
}
