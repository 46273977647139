import React, { useMemo } from 'react';
import {
  FilingStatus,
  GET_GOAL_SETUP_DETAILS,
  GetGoalSetupDetailsQuery,
  UPDATE_GOAL_SETUP_USER,
  UpdateUserInput,
  useMutation,
  useQuery,
  WorkType,
} from '@data';
import { BasicFormBlueprint } from '@app/blueprints';
import { fields } from '@app/config';
import { Route, FieldConfig } from '@types';
import { HandleNextUpdates } from '../GoalSetupStack';

type FormValues = { [key: string]: any };

export interface GoalSetupInfoProps<T extends FormValues> {
  title: string;
  subtitle?: string;
  fields: FieldConfig[];
  getInitialValues: (data?: GetGoalSetupDetailsQuery) => T;
  formatPayload: (data: T) => UpdateUserInput;
  formatNext?: (data: T) => HandleNextUpdates;
  formatOptimistic?: (
    data: T,
    existing: GetGoalSetupDetailsQuery['me']['user'],
  ) => GetGoalSetupDetailsQuery['me']['user'];
  handleNext: (pass?: HandleNextUpdates) => void;
}

const GoalSetupInfo = <T extends FormValues>({
  title,
  subtitle,
  fields,
  getInitialValues,
  formatPayload,
  formatOptimistic,
  formatNext,
  handleNext,
}: GoalSetupInfoProps<T>) => {
  const { loading, data } = useQuery(GET_GOAL_SETUP_DETAILS);
  const [updateUser, { loading: submitting }] = useMutation(UPDATE_GOAL_SETUP_USER);

  const initialValues = useMemo(() => {
    return getInitialValues(data);
  }, [data]);

  const formConfig = {
    initialValues,
    fields,
    onSubmit: (values) => {
      const payload = formatPayload(values);
      // @ts-ignore
      const optimistic = formatOptimistic ? formatOptimistic(values, data?.me?.user) : undefined;
      const nextData = formatNext ? formatNext(values) : undefined;

      updateUser({
        variables: { input: payload },
        optimisticResponse: {
          updateUserNew: {
            // @ts-ignore - not sure how to get this to work properly
            user: {
              ...data?.me?.user,
              ...(optimistic || payload),
              __typename: 'User',
            },
          },
        },
      });

      handleNext(nextData);
    },
  };

  return (
    <BasicFormBlueprint
      loading={loading}
      submitting={submitting}
      title={title}
      subtitles={[subtitle]}
      formConfig={formConfig}
    />
  );
};

export const GoalEmploymentTypeView = {
  name: Route.GOAL_EMPLOYMENT_TYPE,
  component: ({ handleNext }) => (
    <GoalSetupInfo
      title="Employment Type"
      fields={[fields.EMPLOYMENT_TYPE]}
      getInitialValues={(data) => ({
        workType: data?.me?.user?.workType,
      })}
      formatPayload={(values) => ({
        workType: values?.workType,
      })}
      handleNext={handleNext}
    />
  ),
  options: {},
};

export const GoalEstimatedIncomeView = {
  name: Route.GOAL_ESTIMATED_INCOME,
  component: ({ handleNext }) => (
    <GoalSetupInfo<{
      workType?: WorkType;
      estimated1099Income?: number;
      estimatedW2Income?: number;
    }>
      title="Estimated Income"
      fields={[fields.INCOME_1099, fields.INCOME_W2]}
      getInitialValues={(data) => ({
        // @ts-ignore
        workType: data?.me?.user?.workType,
        estimated1099Income: data?.me?.user?.income?.estimated1099Income,
        estimatedW2Income: data?.me?.user?.income?.estimatedW2Income,
      })}
      formatPayload={(values) => ({
        estimated1099Income: values?.estimated1099Income,
        estimatedW2Income: values?.estimatedW2Income,
      })}
      // @ts-ignore
      formatOptimistic={(values, existing) => ({
        income: {
          ...existing?.income,
          estimated1099Income: values?.estimated1099Income,
          estimatedW2Income: values?.estimatedW2Income,
        },
      })}
      handleNext={handleNext}
    />
  ),
  options: {},
};

export const GoalWorkStateView = {
  name: Route.GOAL_WORK_STATE,
  component: ({ handleNext }) => (
    <GoalSetupInfo<{
      workState: string;
    }>
      title="Work State"
      fields={[fields.WORK_STATE]}
      getInitialValues={(data) => ({
        workState: data?.me?.user?.workState,
      })}
      formatPayload={(values) => ({
        workState: values.workState,
      })}
      handleNext={handleNext}
    />
  ),
  options: {},
};

export const GoalTaxFilingStatusView = {
  name: Route.GOAL_TAX_FILING_STATUS,
  component: ({ handleNext }) => (
    <GoalSetupInfo<{ filingStatus: FilingStatus }>
      title="Filing Status"
      fields={[fields.FILING_STATUS]}
      getInitialValues={(data) => ({
        filingStatus: data?.me?.user?.filingStatus,
      })}
      formatPayload={(values) => ({ filingStatus: values.filingStatus })}
      formatNext={(values) => ({ filingStatus: values.filingStatus })}
      handleNext={handleNext}
    />
  ),
  options: {},
};

export const GoalSpouseIncomeView = {
  name: Route.GOAL_SPOUSE_INCOME,
  component: ({ handleNext }) => (
    <GoalSetupInfo<{ spouseIncome: number }>
      title="Spouse Income"
      fields={[fields.SPOUSE_INCOME]}
      getInitialValues={(data) => ({
        spouseIncome: data?.me?.user?.spouseIncome,
      })}
      formatPayload={(values) => ({
        spouseIncome: values.spouseIncome,
      })}
      handleNext={handleNext}
    />
  ),
  options: {},
};

export const GoalDependentsView = {
  name: Route.GOAL_DEPENDENTS,
  component: ({ handleNext }) => (
    <GoalSetupInfo<{ numTaxDependents: number }>
      title="Num. Dependents"
      fields={[fields.DEPENDENTS]}
      getInitialValues={(data) => ({
        numTaxDependents: data?.me?.user?.numTaxDependents,
      })}
      formatPayload={(values) => ({
        numTaxDependents: values.numTaxDependents,
      })}
      handleNext={handleNext}
    />
  ),
  options: {},
};
