import { useMemo } from 'react';
import {
  useQuery,
  useMutation,
  FIND_OR_CREATE_USER,
  GET_CURRENT_USER,
  CACHE_UPDATE_CURRENT_USER,
} from '@data';
import { useToken } from '@global';

export const useCurrentUser = () => {
  const { token } = useToken();

  const [findOrCreateUser] = useMutation(FIND_OR_CREATE_USER, {
    update: (cache, result) => CACHE_UPDATE_CURRENT_USER(cache, result),
  });

  const { data } = useQuery(GET_CURRENT_USER, {
    skip: !token,
  });

  /**
   * Logic for determining whether a user has fully onboarded
   * Current requirements are:
   * - first and last name
   */
  const isOnboarded = useMemo(() => {
    return !!data?.me?.user?.givenName && !!data?.me?.user.familyName;
  }, [data]);

  return {
    user: data?.me.user,
    findOrCreateUser,
    isOnboarded,
  };
};
