import { useState, useEffect } from 'react';
import { isBefore } from 'date-fns';
import { usePlaidLink } from 'react-plaid-link';
import { popToast, ToastType } from '@uikit';
import { CREATE_BANK_LINK, CREATE_LINK_TOKEN, useFintechMutation } from '@app/data';

const handleError = () => {
  popToast({
    type: ToastType.error,
    msg: 'Please try again',
    title: 'Something went wrong',
    autoCloseIn: 3000,
  });
};

export const usePlaid = ({ onSuccess }) => {
  // indicator for disabling the underlying trigger while in flow
  const [disabled, setDisabled] = useState(false);

  // an indicator so that we know whether we should open link once ready
  const [shouldOpenWhenReady, setShouldOpenWhenReady] = useState(false);

  // stashes the payload from creating link token
  const [token, setToken] = useState<string>();
  const [expiration, setExpiration] = useState<Date>();
  const hasExpired = expiration ? isBefore(expiration, new Date()) : false;

  /**
   * Plaid link hook
   */
  const plaid = usePlaidLink({
    token: token || null,
    onSuccess: (publicToken) => {
      createBankLink({ variables: { input: { publicToken } } });
    },
    onExit: () => {
      setDisabled(false);
    },
    onEvent: () => {
      // @todo add analytics tracking here
    },
  });

  /**
   * Handles automatically opening Plaid if user has already initiated
   *
   * We set shouldOpenWhenReady when fetching the token
   * Once the plaid link is ready, we should open it
   */
  useEffect(() => {
    if (plaid.ready && shouldOpenWhenReady) {
      plaid.open();
      setShouldOpenWhenReady(false);
    }
  }, [plaid.ready, shouldOpenWhenReady]);

  /**
   * Data mutation for creating the link token
   * Once generated, we stash the values (token/expiration)
   */
  const [createLinkToken, { loading: creatingToken }] = useFintechMutation(CREATE_LINK_TOKEN, {
    onCompleted: (data) => {
      const { linkToken, expiration } = data.createLinkToken;
      setToken(linkToken);
      setExpiration(new Date(expiration));
    },
    onError: (err) => {
      setDisabled(false);
      handleError();
    },
  });

  /**
   * Data mutation for creating a bank link
   * After a successful plaid link, we need to create the items on our side
   */
  const [createBankLink] = useFintechMutation(CREATE_BANK_LINK, {
    onCompleted: (data) => {
      onSuccess();
      setDisabled(false);
    },
    onError: () => {
      handleError();
    },
  });

  /**
   * Prefetches the link token
   * Use this onHover to get a slightly snappier experience
   */
  const prefetch = () => {
    if ((!token || hasExpired) && !creatingToken) {
      createLinkToken();
    }
  };

  /**
   * Handles opening the plaid link
   * We check here if there's a valid token to reuse, otherwise we fetch
   */
  const openLink = () => {
    setDisabled(true);

    if (!token || hasExpired) {
      setShouldOpenWhenReady(true);

      if (!creatingToken) {
        createLinkToken();
      }
    } else {
      plaid.open();
    }
  };

  /**
   * We show the underlying trigger as loading in the period
   * where we've triggered, but Plaid has not yet been opened
   */
  const loading = disabled && !plaid.ready;

  return {
    disabled,
    loading,
    prefetch,
    openLink,
  };
};
