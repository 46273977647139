import { gql } from '../generated';

gql(`
  fragment BankAccount on BankAccount {
    id
    availableBalance
    pendingBalance
    totalBalance
  }
`);
