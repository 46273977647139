import { gql } from '../generated';

/**
 * Note: unlike some of the other definitions, this one
 * is page specific instead of model specific
 */
export const GET_MONEY_PAGE = gql(`
  query GetMoneyPage {
    bankAccount {
      ...BankAccount
    }
    goals {
      ...Goal
    }
    linkedAccounts {
      ...LinkedAccount
    }
  }
`);
