import React, { useState } from 'react';
import {
  useMutation,
  mutations,
  UpsertExplorerVars,
  UpsertExplorerResponse,
  useDeprecatedMutation,
  useQuery,
  updates,
  ExplorerPlansQuery,
  HealthPathwayType,
} from '@data';
import { navigate, ScreenDefinition } from '@navigate';
import { Segment, SegmentEvent, useCopy } from '@app/utils';
import { HealthPlansBlueprint } from '@app/blueprints';
import { Partner, Route } from '@types';

const ExplorerPlanSelection = ({ pathway, coverageYear, signupCode }) => {
  const { loading, data } = useQuery(ExplorerPlansQuery);
  const { c: c0 } = useCopy('catch.healthExplorer.plans');
  const [selecting, setSelecting] = useState(false);

  const [startEnrollment] = useDeprecatedMutation('upsertEnrollmentFromExplorer', {
    onCompleted: (enrollment) => {
      navigate(Route.EDE_INTRO, {
        enrollId: enrollment.id,
        applicationID: enrollment.healthApplication.id,
      });
    },
  });

  const [update] = useMutation<UpsertExplorerResponse, UpsertExplorerVars>(
    mutations.UPSERT_EXPLORER,
    {
      update: updates.UPDATE_EXPLORER,
    },
  );

  const planSearch = data?.viewerTwo?.healthExplorerData?.healthPlans;

  const selectPlan = async ({ healthPlan, onCompleted }) => {
    Segment.track(SegmentEvent.HX_PLAN_CHOSEN, {
      plan_name: healthPlan.name,
      plan_id: healthPlan.planID,
      plan_metal_level: healthPlan.metalLevel,
      plan_premium: healthPlan.premium,
      plan_premium_with_credit: healthPlan.premiumWithCredit,
      plan_carrier: healthPlan?.issuer?.name,
      plan_type: healthPlan.type,
      pathway: pathway,
      coverage_year: coverageYear,
    });

    setSelecting(true);
    const input = { providerPlanID: healthPlan.planID };

    if (pathway === HealthPathwayType.Ffm) {
      await update({ variables: { input } });

      if (signupCode === Partner.decisely) {
        navigate(Route.EXPLORER_PARTNER_HANDOFF);
      } else {
        await startEnrollment();
        onCompleted();
      }
    } else {
      update({ variables: { input } });
      onCompleted();
      navigate(Route.EXPLORER_STATE_HANDOFF);
    }
  };

  return (
    <HealthPlansBlueprint
      loading={loading}
      selecting={selecting}
      title={c0('title')}
      subtitle={c0('subtitle')}
      initialPlanID={
        data?.viewerTwo?.healthExplorerData?.providerPlanID ||
        planSearch?.topPlans?.catchRecommended?.healthPlan?.planID
      }
      isWindowShopping={data?.reference?.health?.openEnrollmentDates?.isWindowShopping}
      explorerID={data?.viewerTwo?.healthExplorerData?.id}
      previouslySelectedID={data?.viewerTwo?.healthExplorerData?.providerPlanID}
      planSearch={planSearch}
      onSubmit={(plan, onCompleted) => {
        selectPlan({ healthPlan: plan.healthPlan, onCompleted });
      }}
    />
  );
};

export const ExplorerPlanSelectionView: ScreenDefinition = {
  name: Route.EXPLORER_CHOOSE_PLAN,
  component: ExplorerPlanSelection,
  options: {
    ...HealthPlansBlueprint.options,
    title: 'Plans',
  },
};
