import React, { useEffect, useMemo } from 'react';
import Intercom from 'react-intercom';
import LogRocket from 'logrocket';
import { datadogRum } from '@datadog/browser-rum';
import { RumFetchResourceEventDomainContext } from '@datadog/browser-rum';
import { ExperimentalFeature } from '@datadog/browser-core/cjs/tools/experimentalFeatures';

import { Env, Segment, setSentryUser, SegmentEvent } from '@app/utils';
import { useResponsive } from '@uikit';
import { AnalyticsContextQuery, AppPlatform, useDeprecatedMutation, useQuery } from '@data';
import { env } from '@app/global';

// filters out local environments + frequent users
export const enableTracking = (email) => {
  if (!email) return false;
  if (Env.isLocal) return false;

  const users = [
    'mayhew',
    'fenster',
    'cypress+2fa',
    'jeff+2fa',
    'alvin+2fa',
    'matt+2fa',
    'lexi+2fa',
  ];

  const match = users.reduce((acc, user) => acc || email?.includes(user), false);
  return !match;
};

/**
 * Centralizing all web specific support/analytics tools
 *
 * Segment sends to Heap, Mixpanel, Customer.io, and others
 * https://github.com/nhagen/react-intercom/blob/master/src/index.js
 * https://app.logrocket.com/6an89b/catch-production/settings/setup
 * https://app.datadoghq.com/rum/application
 */
const AuthenticatedSupport = () => {
  const { longRange } = useResponsive();

  const { data } = useQuery(AnalyticsContextQuery, {
    variables: { platform: AppPlatform.Web },
  });

  const { userId, givenName, familyName, email, phoneNumber, intercomHash } = useMemo(() => {
    return {
      userId: data?.viewer?.user?.id,
      givenName: data?.viewer?.user?.givenName,
      familyName: data?.viewer?.user?.familyName,
      email: data?.viewer?.user?.email,
      phoneNumber: data?.viewer?.user?.phoneNumber,
      intercomHash: data?.viewer?.intercomIdentityHash,
    };
  }, [data]);

  const [startSession] = useDeprecatedMutation('startSession', {
    variables: {
      input: {
        version: env.VERSION_NUMBER,
        deviceType: 'web',
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
    },
  });

  const enable = Env.isProd || enableTracking(email);

  /**
   * @todo make sure EBP is properly updating
   * @todo only keep this live until end of OE 2023-01-16
   * */
  useEffect(() => {
    startSession();
  }, []);

  useEffect(() => {
    if (enable && userId) {
      Segment.identifyUser(userId, {
        name: `${givenName} ${familyName}`,
        email,
        givenName,
        familyName,
        phoneNumber,
        phone: phoneNumber,
      });

      LogRocket.init(Env.logrocket, {
        rootHostname: 'catch.co',
        network: {
          requestSanitizer: (request) => {
            if (request.url.toLowerCase() === 'https://cognito-idp.us-west-2.amazonaws.com/') {
              request.body = null;
            }

            if (request?.body?.variables?.user?.ssn) {
              request.body.variables.user.ssn = '';
            }

            return request;
          },
        },
      });

      LogRocket.identify(userId, {
        name: `${givenName} ${familyName}`,
        email,
        phoneNumber,
        phone: phoneNumber,
      });

      LogRocket.getSessionURL(function (sessionURL) {
        Segment.track(SegmentEvent.LOGROCKET, { sessionURL });
      });

      setSentryUser({ email, id: userId });
    }

    // Eventually we want to check the enable flag, but for testing
    // we want it to run in dev
    if (userId) {
      if (process.env.REACT_APP_DATADOG_RUM_CLIENT_TOKEN && Env.datadogrum) {
        const ddconfig = Env.datadogrum;
        console.log(`initiating rum ${ddconfig.applicationId} ${ddconfig.env}`);

        datadogRum.init({
          enableExperimentalFeatures: [ExperimentalFeature.WRITABLE_RESOURCE_GRAPHQL],
          applicationId: ddconfig.applicationId,
          clientToken: process.env.REACT_APP_DATADOG_RUM_CLIENT_TOKEN,
          // `site` refers to the Datadog site parameter of your organization
          // see https://docs.datadoghq.com/getting_started/site/
          site: 'datadoghq.com',
          service: 'app',
          env: ddconfig.env,
          // Specify a version number to identify the deployed version of your application in Datadog
          version: env.VERSION_NUMBER,
          sessionSampleRate: 100,
          sessionReplaySampleRate: 100,
          trackUserInteractions: true,
          trackSessionAcrossSubdomains: true,
          trackResources: true,
          trackLongTasks: true,
          defaultPrivacyLevel: 'allow',
          allowedTracingUrls: [
            { match: 'https://api.catch.co', propagatorTypes: ['tracecontext'] },
            { match: 'https://api.dev.catch.co', propagatorTypes: ['tracecontext'] },
            { match: 'https://api.stage.catch.co', propagatorTypes: ['tracecontext'] },
          ],
          beforeSend: (event, context) => {
            //ignore certain types of requests - don't send to DD
            if (['xhr'].includes(event.type) === true) {
              //console.log(`skipping event ${event.type}`);
              return false;
            }

            // enahnce the data collected
            if (event.type === 'resource' && event.resource.type === 'fetch' && context !== null) {
              // is there a better way to force the type hinting? We know what this is
              // when we're here
              const ctx: RumFetchResourceEventDomainContext = context;
              // these are sparse
              if (ctx.response!.headers) {
                event!.context!.responseHeaders = Object.fromEntries(ctx.response!.headers);
              }
              // these are full
              if (ctx!.requestInit!.headers) {
                event!.context!.requestHeaders = ctx!.requestInit!.headers;
              }
            }
            return true;
          },
        });
      }

      datadogRum.setUser({
        id: userId,
        name: `${givenName} ${familyName}`,
        email: email,
        phone: phoneNumber,
      });

      const ddSessionUrl = datadogRum.getSessionReplayLink();
      if (ddSessionUrl) {
        Segment.track(SegmentEvent.BROWSER_REPLAY, { sessionURL: ddSessionUrl });
      }
    }
  }, [userId, givenName, familyName, email, phoneNumber, enable]);

  return (
    <>
      {userId && (
        <Intercom
          appID={Env.isProd ? 'pniw40fg' : 'qn5cenup'}
          vertical_padding={longRange === 'mobile' ? 84 : 20}
          user_id={userId}
          name={`${givenName} ${familyName}`}
          email={email}
          user_hash={intercomHash}
          hide_default_launcher={true}
        />
      )}
    </>
  );
};

export default AuthenticatedSupport;
